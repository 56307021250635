import Vue from "../vue.js"

import RxTime from "../plugins/RxTime"

if (document.querySelectorAll("#maintenance").length > 0) {
  new Vue({
    mixins: [RxTime.mixin()],
    el: "#maintenance",
    computed: {
      diff() {
        const till = this.$moment("2019.06.13 15:00:00 +02:00", "YYYY-MM-DD HH:mm:ii Z")
        const now = this.$moment(this.now)
        return till.diff(now)
      },
      downTill() {
        if (this.$moment(this.now).isValid()) {
          return {
            hours: this.$moment.utc(this.diff).format("H"),
            minutes: this.$moment.utc(this.diff).format("mm"),
            seconds: this.$moment.utc(this.diff).format("ss"),
          }
        } else {
          return {
            hours: "-",
            minutes: "-",
            seconds: "-",
          }
        }
      },
    },
    methods: {
      contactSupport() {
        SupportCenter.enable()
        SupportCenter.showNewMessage("")
      },
    },
  })
}
